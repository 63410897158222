@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');

h1 {
  font-family: 'Black Han Sans', sans-serif !important;
}

#root {
  height: 100%;
  background: url("./images/entering-alt.jpg") no-repeat top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
   background-size: cover;
}
.canada svg {
  fill: red;
}
.chakra-text svg {
  display: inline-block;
}


.box-bg {
  background: rgba(0,0,0,0.4);
}
.social svg {
  display: inline-block;
  margin-right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
}

/* MailChimp Overrides */
#mc_embed_signup form {
  padding-left: 0 !important;
}
#hidden-mc-field {
  position: absolute; 
  left: -5000px;
}